// * "Hello World"  -> true                                                                                         
// * "Hello123"     -> false                                                                                        
// * "Hello_World"  -> false                                                                                        
// * "Hello World!" -> false                                                                                        
// * "     "        -> false (only spaces)                                                                          
// * ""             -> false (empty string)                                                                         
// * "123445"       -> false (only numbers)  
//can be used for validating fullnames, etc
export const FULLNAME_REGEX: RegExp = /^(?!\s*$)[a-zA-Z\s]*$/;

// vehicle numbers can start with numbers alphabets also contain spaces but no special characters
export const VEHICLE_NUMBER_REGEX: RegExp = /^[A-Z0-9]+(?:\s[A-Z0-9]+)*$/;


/**
 * Accepts <any number of (letters,numbers)>@<any number of (letters,numbers)>.<2-4 number of (letters)>
 * test#$@gmail.com -> false
 */
export const EMAIL_REGEX: RegExp = /^[\w\-\.]+@([\w\-]+\.)+[a-zA-Z]{2,4}$/


//upi regex
// [0-9A-Za-z.-]{2,256} − In the start, it should contain 2 to 256 alphanumeric, dot, or hyphen characters.

// @ − It should always contain @ character.

// [A-Za-z]{2,64} − After the ‘@’ character, it should contain 2 to 64 alphabetical characters.
export const UPI_REGEX: RegExp = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/


// * "07" -> false (length less than 12)                                                                           
// * "AB" -> false (string)                                                                                        
// * "AB@" -> false (string with special character)                                                                
// * "123456789112" true (number with length 12)  
// can be used for Aadhar card validation.
export const AADHAR_NUMBER_REGEX: RegExp = /^[0-9]{12}$/;



//According to RBI Reserve Bank of India -> The bank should have 9-18 digits
export const INDIAN_BANK_ACCOUNT_NO_REGEX: RegExp = /^\d{9,18}$/;

// According to Indian Financial System Code on wikipedia
export const INDIAN_IFSC_REGEX: RegExp = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/


// * "any character not allowed" -> false                                                                        
// * "1213" -> true           
// only numbers allowed any other  character not allowed
// can be used for number validation
export const NUMBERS_REGEX: RegExp = /^[0-9]+$/;


// [a-zA-Z0-9\s\-\.] : Allows alphabets (both upper and lower case), numbers, spaces (\s), hyphens (\-), and periods (\.).
// {2,50} : Length of the branch name should be between 2 and 50 characters.
export const BANK_BRANCH_NAME_REGEX: RegExp = /^[a-zA-Z0-9\s\-\.]{2,50}$/;

// Alphabets Only: Bank names usually consist of letters. They may allow spaces or special characters, but typically no digits.
// Case Insensitive: The name can be in upper or lower case.
// Spaces Allowed: Multiple words can be part of a bank name, so spaces should be permitted.
// Special Characters: Certain punctuation marks like hyphens (-), periods (.), and apostrophes (') are often allowed.
export const BANK_NAME_REGEX: RegExp = /^[a-zA-Z\s\-\.\']{2,100}$/


// Accepts all the strings except but not a string which is only filled with empty spaces
export const NON_EMPTY_STRING: RegExp = /(.|\s)*\S(.|\s)*/

// Accepts numbers that are positve or negative with decimal places
export const NUMBER_POSITIVE_NEGATIVE_DECIMAL: RegExp = /^-?\d+(\.\d+)?$/

// Accepts only integers and decimal number
export const INTEGER_DECIMAL_NUMBER: RegExp = /^\d+(\.\d+)?$/

// Used to validate a url
export const LINK: RegExp = /^(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;













































// * "HELLOWORLD 123"  ->  false (contains spaces)                                                                                     
// * "HELLO123"        ->  true                                                                                     
// * " H"              ->  false (does not start with an uppercase letter)                                          
// * "123HELLO"        ->  false (does not start with an uppercase letter)                                          
// * " "               ->  false (only spaces)                                                                      
// * "HELLO WORLD!"    ->  false (contains an exclamation mark)                                                     
// * ""                ->  false (empty string) 
// can be used for vehicle number, Bank IFSC codes, etc validation
export const STRING_UPPER_ONLY_NUMBERS_REGEX: RegExp = /^[A-Z][A-Z0-9]*$/;


// * "hello WORLD 123" ->  true                                                                                     
// * "HELLO123"        ->  true                                                                                                                            
// * "123HELLO"        ->  false (does not start with an letter)   
// * " H"              ->  false (string starts with space)                        
// * " "               ->  false (only spaces)                                                                      
// * "HELLO WORLD!"    ->  false (contains an exclamation mark)                                                     
// * ""                ->  false (empty string) 
// can be used for string that can contain alphabets numbers spaces
export const STRING_UPPER_LOWER_NUMBER_SPACES: RegExp = /^[a-zA-Z][a-zA-Z0-9 ]*$/;





//accepts time in '08:30 AM' this format
export const TIME_REGEX: RegExp = /(\d{2}):(\d{2}) (AM|PM)/;



// * "any character not allowed" -> false
// * "123abc" -> true
// * "abc123" -> true
// * "123" -> true
// * "abc" -> true
// * "123_abc" -> false
// * "abc-123" -> false
// only alphabets and numbers allowed, any other character not allowed
// can be used for alphanumeric validation
export const ALPHANUMERIC_REGEX: RegExp = /^[a-zA-Z0-9]+$/;




export const STRING_UPPER_LOWER_TRAILING_NUMBER_REGEX: RegExp = /^[a-zA-Z0-9]+$/;
export const STRING_UPPER_LOWER_SPECIAL_CHARACTERS_REGEX: RegExp = /[^0-9]/g;





export const PAN_CARD_REGEX: RegExp = /^[A-Z]{5}[0-9]{4}[A-Z]$/


export const PASSPORT_NUMBER_REGEX: RegExp = /^[A-Z0-9]{1,10}$/